.tooltipEntry {
  -webkit-font-smoothing: antialiased;
  animation: scaledFadeInUp 200ms;
  animation-timing-function: cubic-bezier(0.175, 0.885, 0.4, 1.1);
}

@keyframes scaledFadeInUp {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
